import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "businessGiving", "customerGiving", "otherGiving" ];
  
  connect(e) {
    console.log("connect add donation!");
  }
  
  setGiving(e) {
    let target = e.currentTarget;
    console.log("change")
    let givingType = target.value;
    if(givingType == "business") {
      this.businessGivingTarget.classList.remove("d-none");
      this.customerGivingTarget.classList.add("d-none");
      this.otherGivingTarget.classList.add("d-none");
    } else if(givingType == "customer") {
      this.businessGivingTarget.classList.add("d-none");
      this.customerGivingTarget.classList.remove("d-none");
      this.otherGivingTarget.classList.add("d-none");
    } else {
      this.businessGivingTarget.classList.add("d-none");
      this.customerGivingTarget.classList.add("d-none");
      this.otherGivingTarget.classList.remove("d-none");
    }
  }

}
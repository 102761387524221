import { Controller } from 'stimulus'
import { loadScript } from "@paypal/paypal-js";
import { logException } from "../utils/helpers.js"

export default class extends Controller {
  static values = {
    clientId: String,
    vault: { type: Boolean, default: true },
    currency: String,
    onSuccessRedirectTo: { type: String, default: "/" },
  }
  static targets = ["cardNumber", "expirationDate", "cvv", "cardHolderName"];

  async connect() {
    try {
      this.paypal = await loadScript({
        components: "card-fields",
        "client-id": this.clientIdValue,
        vault: this.vaultValue,
        currency: this.currencyValue,
      })
    } catch (err) {
      logException(err);
      console.error("failed to load the PayPal JS SDK script", err);
      return;
    }

    this.setCardFields();
  }
  
  get paypalClientID() {
    return document.head.querySelector("meta[name=paypal_client]").content
  }

  async getBaInitToken() {
    let response = await fetch("/paypal/ba_init_token.json");
    return response.ba_token;
  }

  createVaultSetupToken() {
    return fetch("/paypal/setup_token.json")
      .then((response) => response.json())
      .then((data) => data.ba_token);
  }

  onApprove(vaultSetupToken) {
    return fetch("/paypal/payment_token.json", {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": document.head.querySelector("meta[name=csrf-token]").content
      },
      body: JSON.stringify({setup_token: vaultSetupToken.vaultSetupToken})
    })
      .then((response) => response.json())
      .then(() => {
        console.info(`PayPal payment connection successful. Redirecting to: ${this.onSuccessRedirectToValue}`);
        window.location.href = this.onSuccessRedirectToValue
      })
      .catch(error => {
        logException(error);
        let existingAlert = document.querySelector(".card-block .alert")
        if(existingAlert) existingAlert.remove();
        let alert = document.createElement("div");
        alert.setAttribute("class", "alert alert-danger");
        alert.innerHTML = "Oops! It looks like there was an error linking your Paypal account with Virtue. Please try again or contact support if you still have issues.";
        document.querySelector(".card-block").prepend(alert);
      });
  }

  onError(e) {
    console.error(e);
  }

  submit() {
    this.cardFields
      .submit()
      .then(() => {
        console.log("submit was successful");
      })
      .catch((error) => {
        console.error("submit errored:", error);
      });
  }

  setCardFields() {
    if (!this.paypal) return;

    this.cardFields = this.paypal.CardFields({
      createVaultSetupToken: this.createVaultSetupToken,
      onApprove: token => this.onApprove(token),
      onError: e => this.onError(e),
      onCancel: e => this.onError(e),
    })

    // TODO: add better error handling
    if (!this.cardFields.isEligible()) return;

    this.cardFields.NumberField().render(this.cardNumberTarget);
    this.cardFields.ExpiryField().render(this.expirationDateTarget);
    this.cardFields.CVVField().render(this.cvvTarget);
    this.cardFields.NameField().render(this.cardHolderNameTarget);
  }
}
